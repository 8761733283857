export const financialFormulas = {
  // earningsPerShare: '=netIncome<this>/sharesOutstanding<this>',
  freeCashFlowPerShare: '=freeCashFlow<this>/sharesOutstanding<this>',
  revenueChange: '=(revenue<this>/revenue<this><previous>-1)*100',
  margin: '=netIncome<this>/revenue<this>*100',
  percentageCfoOfRevenue: '=cashFlowFromOperatingActivities<this>/revenue<this>*100',
  capexChange: '=(capex<this>/capex<this><previous>-1)*100',
  freeCashFlow: '=cashFlowFromOperatingActivities<this>+capex<this>',
  totalAssetsChange: '=(totalAssets<this>/totalAssets<this><previous>-1)*100',
  percentageLiabilitiesOfAssets: '=totalLiabilities<this>/totalAssets<this>*100',
  shareholdersEquity: '=totalAssets<this>-totalLiabilities<this>',
  sharesOutstandingChange: '=(sharesOutstanding<this>/sharesOutstanding<this><previous>-1)*100',
  roa: '=netIncome<this>/totalAssets<this>*100',
  roe: '=netIncome<this>/shareholdersEquity<this>*100',
  // averageMarketCap: '=averageStockPrice<this>*sharesOutstanding<this>',
  // freeCashFlowYield: '=freeCashFlow<this>/averageMarketCap<this>*100',
};

export const estimatedFormulas = {
  ...financialFormulas,
  year: 0,
  earningsPerShare: '=netIncome<this>/sharesOutstanding<this>',
  freeCashFlowPerShare: '=freeCashFlow<this>/sharesOutstanding<this>',
  revenue: '=revenue<actual><last>*(1+revenueChange<this>/100)',
  revenueChange: '=revenueChange<average><last>',
  netIncome: '=revenue<this>*(margin<this>/100)',
  margin: '=margin<average><last>',
  cashFlowFromOperatingActivities: '=revenue<this>*(percentageCfoOfRevenue<this>/100)',
  percentageCfoOfRevenue: '=percentageCfoOfRevenue<average><last>',
  capex: '=capex<actual><last>*(1+capexChange<this>/100)',
  capexChange: '=capexChange<average><last>',
  totalAssets: '=totalAssets<actual><last>*(1+totalAssetsChange<this>/100)',
  totalAssetsChange: '=totalAssetsChange<average><last>',
  totalLiabilities: '=totalAssets<this>*(percentageLiabilitiesOfAssets<this>/100)',
  percentageLiabilitiesOfAssets: '=percentageLiabilitiesOfAssets<average><last>',
  shareholdersEquity: '=totalAssets<this>-totalLiabilities<this>',
  sharesOutstanding: '=sharesOutstanding<actual><last>*(1 + sharesOutstandingChange<this> / 100)',
  sharesOutstandingChange: '=sharesOutstandingChange<average><last>',
  roa: '=netIncome<this>/totalAssets<this>*100',
  roe: '=netIncome<this>/shareholdersEquity<this>*100',
  freeCashFlowYield: '=freeCashFlow<this>/averageMarketCap<this>*100',
  requiredReturn: 10,
  freeCashFlowGrowthRate: 0,
  pvBreakdown: '=freeCashFlow<this>/(1+requiredReturn<this>/100)',
  netDebtEstimate: '=-1*netDebt<actual><last>',
  minorityInterest: '=minorityInterest<actual><last>',
  enterpriseValue: '=NPV(requiredReturn<estimated><last>/100,freeCashFlow<estimated><0>,freeCashFlow<projected><0>:freeCashFlow<projected><last-1>)+terminalValue<projected><last>/(1+requiredReturn<this>/100)**5',
  // averageMarketCap: '=enterpriseValue<this>+netDebtEstimate<this>+minorityInterest<this>',
  averageMarketCap: '=enterpriseValue<this>-1*netDebt<actual><last>+minorityInterest<actual><last>',
  averageStockPrice: '=averageMarketCap<this>/sharesOutstanding<this>',
};

export const averageFormulas = {
  year: 'Average (A)',
  earningsPerShare: '=((AVERAGE(earningsPerShare<actual><last-1>:earningsPerShare<actual><last>)/AVERAGE(earningsPerShare<actual><0>:earningsPerShare<actual><1>))**(1/10)-1)*100',
  freeCashFlowPerShare: '=((AVERAGE(freeCashFlowPerShare<actual><last-1>:freeCashFlowPerShare<actual><last>)/AVERAGE(freeCashFlowPerShare<actual><0>:freeCashFlowPerShare<actual><1>))**(1/10)-1)*100',
  dividendsPerShare: '=((AVERAGE(dividendsPerShare<actual><last-1>:dividendsPerShare<actual><last>)/AVERAGE(dividendsPerShare<actual><0>:dividendsPerShare<actual><1>))**(1/10)-1)*100',
  dividendsPayout: '=((AVERAGE(dividendsPayout<actual><last-1>:dividendsPayout<actual><last>)/AVERAGE(dividendsPayout<actual><0>:dividendsPayout<actual><1>))**(1/10)-1)*100',
  revenue: '=((AVERAGE(revenue<actual><last-1>:revenue<actual><last>)/AVERAGE(revenue<actual><0>:revenue<actual><1>))**(1/10)-1)*100',
  revenueChange: '=((AVERAGE(revenue<actual><last-1>:revenue<actual><last>)/AVERAGE(revenue<actual><0>:revenue<actual><1>))**(1/10)-1)*100',
  netIncome: '=((AVERAGE(netIncome<actual><last-1>:netIncome<actual><last>)/AVERAGE(netIncome<actual><0>:netIncome<actual><1>))**(1/10)-1)*100',
  margin: '=AVERAGE(margin<actual><0>:margin<actual><last>)',
  cashFlowFromOperatingActivities: '=((AVERAGE(cashFlowFromOperatingActivities<actual><last-1>:cashFlowFromOperatingActivities<actual><last>)/AVERAGE(cashFlowFromOperatingActivities<actual><0>:cashFlowFromOperatingActivities<actual><1>))**(1/10)-1)*100',
  percentageCfoOfRevenue: '=AVERAGE(percentageCfoOfRevenue<actual><0>:percentageCfoOfRevenue<actual><last>)',
  capex: '=((AVERAGE(capex<actual><last-1>:capex<actual><last>)/AVERAGE(capex<actual><0>:capex<actual><1>))**(1/10)-1)*100',
  capexChange: '=((AVERAGE(capex<actual><last-1>:capex<actual><last>)/AVERAGE(capex<actual><0>:capex<actual><1>))**(1/10)-1)*100',
  freeCashFlow: '=((AVERAGE(freeCashFlow<actual><last-1>:freeCashFlow<actual><last>)/AVERAGE(freeCashFlow<actual><0>:freeCashFlow<actual><1>))**(1/10)-1)*100',
  totalAssets: '=((totalAssets<actual><last>/totalAssets<actual><0>)**(1/10)-1)*100',
  totalAssetsChange: '=((totalAssets<actual><last>/totalAssets<actual><0>)**(1/10)-1)*100',
  totalLiabilities: '=((totalLiabilities<actual><last>/totalLiabilities<actual><0>)**(1/10)-1)*100',
  /* percentageLiabilitiesOfAssets: '=AVERAGE(percentageLiabilitiesOfAssets<actual><0>:
  percentageLiabilitiesOfAssets<actual><last>)', */
  percentageLiabilitiesOfAssets: '=percentageLiabilitiesOfAssets<actual><last>',
  shareholdersEquity: '=((shareholdersEquity<actual><last>/shareholdersEquity<actual><0>)**(1/10)-1)*100',
  netDebt: '=((netDebt<actual><last>/netDebt<actual><0>)**(1/10)-1)*100',
  sharesOutstanding: '=((sharesOutstanding<actual><last>/sharesOutstanding<actual><0>)**(1/10)-1)*100',
  sharesOutstandingChange: '=((sharesOutstanding<actual><last>/sharesOutstanding<actual><0>)**(1/10)-1)*100',
  roa: 0,
  roe: 0,
  interestCoverage: 0,
  dividendYield: 0,
  averageMarketCap: '=((AVERAGE(averageMarketCap<actual><last-1>:averageMarketCap<actual><last>)/AVERAGE(averageMarketCap<actual><0>:averageMarketCap<actual><1>))**(1/10)-1)*100',
  freeCashFlowYield: '=((AVERAGE(freeCashFlowYield<actual><last-1>:freeCashFlowYield<actual><last>)/AVERAGE(freeCashFlowYield<actual><0>:freeCashFlowYield<actual><1>))**(1/10)-1)*100',
  averageStockPrice: '=((AVERAGE(averageStockPrice<actual><last-1>:averageStockPrice<actual><last>)/AVERAGE(averageStockPrice<actual><0>:averageStockPrice<actual><1>))**(1/10)-1)*100',
};

export const firstProjectedFormulas = {
  ...estimatedFormulas,
  revenue: '=revenue<estimated><last>*(1+revenueChange<this>/100)',
  netIncome: '=revenue<this>*(margin<this>/100)',
  cashFlowFromOperatingActivities: '=revenue<this>*(percentageCfoOfRevenue<this>/100)',
  capex: '=capex<estimated><last>*(1+capexChange<this>/100)',
  freeCashFlow: '=cashFlowFromOperatingActivities<this>+capex<this>',
  totalAssets: '=totalAssets<estimated><last>*(1+totalAssetsChange<this>/100)',
  totalLiabilities: '=totalAssets<this>*(percentageLiabilitiesOfAssets<this>/100)',
  sharesOutstanding: '=sharesOutstanding<estimated><last>*(1+sharesOutstandingChange<this>/100)',
  requiredReturn: undefined,
  freeCashFlowGrowthRate: undefined,
  terminalValue: undefined,
  netDebtEstimate: undefined,
};

export const projectedFormulas = {
  ...financialFormulas,
  earningsPerShare: '=netIncome<this>/sharesOutstanding<this>',
  freeCashFlowYield: '=freeCashFlow<this>/averageMarketCap<this>*100',
  revenue: '=revenue<this><previous>*(1+revenueChange<this>/100)',
  netIncome: '=revenue<this>*(margin<this>/100)',
  cashFlowFromOperatingActivities: '=revenue<this>*(percentageCfoOfRevenue<this>/100)',
  capex: '=capex<this><previous>*(1+capexChange<this>/100)',
  freeCashFlow: '=cashFlowFromOperatingActivities<this>+capex<this>',
  totalAssets: '=totalAssets<this><previous>*(1+totalAssetsChange<this>/100)',
  totalLiabilities: '=totalAssets<this>*(percentageLiabilitiesOfAssets<this>/100)',
  sharesOutstanding: '=sharesOutstanding<this><previous>*(1+sharesOutstandingChange<this>/100)',
  terminalValue: undefined,
  minorityInterest: '=-minorityInterest<actual><last>',
};

export const emptyData = {
  founded: 0,
  employees: 0,
  summary: '',
  symbol: '',
  name: '',
  shortName: '',
  keywords: '',
  logo: '',
  stockPrice: 0,
  stockPriceChange: 0,
  week52High: 0,
  week52Low: 0,
  lastTradeTime: new Date().getTime(),
  latestUpdate: new Date().getTime(),
};

export const emptyValuation = {
  freeCashFlowYield: 0,
  dividendYield: 0,
  marketCap: 0,
  priceEarningsRatioLtm: 0,
  priceBookRatio: 0,
  peg: 0,
  totalReturnLtm: 0,
  totalReturn5y: 0,
  totalReturn10y: 0,
  freeCashFlowPerShareLtm: 0,
  freeCashFlowPerShare5y: 0,
  freeCashFlowPerShare10y: 0,
  earningsPerShareLtm: 0,
  earningsPerShare5y: 0,
  earningsPerShare10y: 0,
};

export const emptyFinancials = {
  actual: [],
  quarterly: [],
  average: [],
  terminal: [],
  estimated: [],
  projected: [],
};

export const emptyStockPriceInPeriod = {
  period: '',
  datetime: '',
  value: 0,
};
